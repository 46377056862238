<template>
	<div class="container">
		<div class="grid justify-content-center">
			<div class="col md:col-5">
			<Message @close="errorMsg=null" class="fadeinUp" v-if="errorMsg" severity="error" key="error">{{errorMsg}}</Message>
				<div class="card">
					<div class="my-3">
						<div class="text-2xl font-bold">Password Reset </div>
					</div>
					<form ref="observer" tag="form" @submit.prevent="resetPassword()">
						<div class="q-my-md">
							<Password :feedback="true" toggleMask v-model="formData.password" placeholder="New Password" />
						</div>
						<div class="q-my-md">
							<Password :feedback="true" toggleMask v-model="formData.confirm_password" placeholder="Confirm new password"
							 />
						</div>
						<div class="text-center">
							<Button type="submit" :loading="saving" label="Change Password" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import useVuelidate from '@vuelidate/core';
	import { required, sameAs } from '@vuelidate/validators';
	export default {
		setup () {
			return { v$: useVuelidate() }
		},
		props: {},
		data: function () {
			return {
				formData: {
					password: "",
					confirm_password: "",
					token: "",
					email: ""
				},
				saving: false,
				errorMsg: null,
			};
		},
		validations() {
			let formData =  {
				password: { required },
				confirm_password: { 
					required, 
					sameAsPassword: sameAs(this.formData.password),
				}
			}
			return {formData}
		},
		methods: {
			resetPassword() {
				const isFormValid = !this.v$.$invalid;
				if(!isFormValid){
					app.flashMsg("Please complete the form", "Form is invalid", "error");
					return;
				}
				this.saving = true;
				this.formData.token = this.$route.query.token || null;
				this.formData.email = this.$route.query.email || null;
				this.$api.post("auth/resetpassword", this.formData).then((response) => {
					this.saving = false;
					this.$router.push("/index/resetpassword_completed");
				},
				(request) => {
					this.saving = false;
					this.errorMsg = request?.response?.data || "Unable to complete request";
				});
			},
		}
	};
</script>